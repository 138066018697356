.main {
    background: white;
    max-width: 1100px;
    padding: 15px !important;
    box-shadow: 0px 5px 8px black;
    margin: 0px auto;
}

.main .text-wrap {
    border: 2px solid #8bc540;
    padding: 50px;
}

.main .text-wrap .logo-wrap {
    width: 400px;
    height: auto;
    margin: 10px auto;
}

.full {
    width: 100%;
    height: 100%;
}

.content .header {
    font-size: 50px;
    color: #8bc540;
    font-weight: 400;
    text-align: center;
    text-transform: capitalize;
    font-family: Arial, Helvetica, sans-serif;
}

.certify {
    text-align: center;
}

.content .certify {
    margin-top: 50px;
}

.content .certify .nameof small {
    font-size: 25px;
    display: block;
}

.content .certify .nameof h2 {
    font-size: 25px;
    text-transform: capitalize;
}

.content .certify .nameof h2 span {
    text-transform: uppercase;
}

.content .certify .nameof {
    border-bottom: 1px dotted rgb(70, 67, 67);
}

.content .validity {
    display: flex;
    margin-top: 50px;
    gap: 15px;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-around;
}

.content .validity .item h2,
.dateItem h2 {
    font-size: 1.3rem;
    text-transform: uppercase;
}

.content .validity .item p {
    margin-top: 15px;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    border-top: 2px dotted #ccc;
}

.signature {
    height: 27px;
}

.item2 span {
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: sans-serif;
}