.disabledpackedeailbtn{
    background: #ccc !important;
    cursor: not-allowed !important;
}
.otpbox22 {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .otpbox22 input {
    width: 40px !important;
    height: 40px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .otpbox22 input:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,.25);
  }
  
  .otpbox22 input::-webkit-inner-spin-button,
  .otpbox22 input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .otpbox22 input[type=number] {
    -moz-appearance: textfield;
  }