.modal-cssclasss{
    margin-top: 10%;
}
.modalpinoverlayclass{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 1040;
}
.lock-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .lock-screen__content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* .lock-screen__title {
    font-size: 24px;
    color: #0077ff;
  } */
   .lock-screen__imgae{
    width: 185px;
   }
  
  .lock-screen__greeting {
    font-size: 20px !important;
    margin: 10px 0;
  }
  
  .lock-screen__message {
    font-size: 18px;
    color:black;
    
  }
  
  .lock-screen__instruction {
    font-size: 14px;
    margin: 20px 0 10px;
    color: #666;
  }
  
  .pin-inputs {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .pin-input {
    width: 40px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .unlock-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background: #0077ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .divider::before,
  .divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ccc;
  }
  
  .divider span {
    margin: 0 10px;
    color: #666;
  }
  
  .password-unlock {
    background: none;
    border: none;
    color: #0077ff;
    cursor: pointer;
    font-size: 14px;
  }
  .modal-content-otp{
    padding: 20px;
    text-align: center;
  }
  
  .modaltitle-otp {
    font-size: 24px !important;
    color: #2c2c86;
    margin-left: 30%;
  }
  
  .verification-timer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .resend-otp {
    color: blue;
    cursor: pointer;
  }
  
  .otp-inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .otp-input {
    width: 14%;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .btn-submit-otp {
    background: linear-gradient(to right, #b0e057, #6c79ff);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  .btn-resend-otp{
    background:  #6c79ff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;

  }
  
  .btn-submit-otp:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  @media only screen and (min-width: 600px) and (max-width: 1024px)  { 
    .mediaqurymodalpop{
      margin-top: 35%;
    }

   }
   @media (max-width: 767.98px) {
    .mediaqurymodalpop{
      margin-top: 46%;
    }
   }
   @media only screen and (min-width: 1025px) {
   }

  

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

input[type="text"] {
    width: 100%;
    box-sizing: border-box;
}

input[type="checkbox"] {
    margin: 0;
}

button.project {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
}

button.medium {
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
}


@keyframes blink {
  50% {
    opacity: 0;
  }
}

.blinking-button {
  animation: blink 1s infinite;
  border: none;
  border-radius: 10px;
  margin:10px ;
  padding: 10px;
  font-size: 26px !important;
  color: red;
}