
.footerrowsignclass{
  margin-top: -1%;
}

.footercss_class{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

}
.policy-content {
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
  }
  
  .policy-content p {
    margin-bottom: 1rem;
  }
  
  .modal-content {
    border-radius: 0.5rem;
  }
  
  .modalheaderpolicy {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  
  .modalfooterpolicy {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  @media (min-width: 1441px) {

.footerlasssign{
  height: 5%;
}
.footerrowsignclass{
  margin-top: -2%;
}


  }