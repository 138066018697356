.dashboard {
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
}

.cards {
  background-color: #604770;
  color: white;
  border-radius: 8px;
  padding: 15px;
  /* width: 200px;
    height: 90px; */
  margin: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  text-align: center;
}

.card:hover {
  transform: translateY(-5px);
}

.card-title {
  font-size: 18px;
}

.card-count {
  font-size: 24px;
  font-weight: bold;
}
.card-icon{
font-size: 25px;
}
.icon-contain{
  display: flex;
  justify-content: space-around;
}